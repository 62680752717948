.entry {
	padding-top: 10px;
	font-weight: 400;
	font-size: 16px;
	display: block;
	text-decoration: none;
	color: black;
}

.entry-term {
	font-family: Poppins;
	font-style: normal;
	font-weight: 800;
	font-size: 16px;
	line-height: 24px;
	color: #8EA0CF;
}

p:nth-child(2){
	display: inline;
	margin-left: 10px;
}

p{
	line-height: 24px;
}

ul {
	margin-top: 4px;
	margin-bottom: 4px;
}