body {
  background: #FFFFFF;
}

/*For markdown pre tags*/
pre{
  white-space:pre-wrap
}

.header {
  background: linear-gradient(180deg, #7395ED 0%, #DBE5FF 100%);
  align-items: center;
  justify-content: center;
  color: white;
  padding-top: 15px;
  padding-bottom: 30px;
  padding-left: 15%;
  padding-right: 15%;
  text-align: center;
}

.header-text {
  font-family: Poppins;
  font-style: normal;
  font-weight: 800;
  font-size: 50px;
  text-align: center;
  text-decoration: none;
  color: #FFFFFF;
  margin-top: 10px;
  /*margin-bottom: 20px;*/
  display: block;
}

.header-search-input-button-div{
  display: flex;
  justify-content:center;
}

.header-search-input {
  width: 80%;
  height: 20px;
}

.header-search-button {
  width: 45px;
  background-color: #8EA0CF;
  border: 0px;
  height: 28px;
}
.header-search-button img {
  margin: 5px;
  height: 18px;
}

.help-button{
  position: absolute;
  right: 30px;
  top: 30px;
  width: 40px;
  height: 30px;
}

.hoot-logo {
  width: 2.5em;
}

/** Body **/

.caw-body{
  display: flex;
  flex-direction: horizontal;
  margin-bottom: 30px;
}

.search-result-container{
  width: 75%;
  padding-left: 30px;
  padding-top: 25px;
}




.search-result-body{
/*    border: 1px solid #8EA0CF;
*/}

.search-result-header {
  font-family: Poppins;
  font-style: normal;
  font-weight: 800;
  font-size: 20px;
  color: #8EA0CF;
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 10px;
  border: 1px solid #8EA0CF;
}

.search-result-header-wo-border {
  font-family: Poppins;
  font-style: normal;
  font-weight: 800;
  font-size: 20px;
  color: #8EA0CF;
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 10px;
  border-left: 1px solid #8EA0CF;
  border-right: 1px solid #8EA0CF;
}

.search-result-header-inverse{
  font-family: Poppins;
  font-style: normal;
  font-weight: 800;
  font-size: 20px;
  background-color: #8EA0CF;
  color: white;
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 10px;
  border: 1px solid #8EA0CF;
}

.search-result-results {
  padding: 20px;
  padding-top: 10px;
  border: 1px solid #8EA0CF;
  border-top: 0;
}

.link-sidebar {
  width: 25%;
  padding-left: 5%;
  padding-right: 5%;
}

.shortcuts-table {
  border-collapse: collapse;
  border: 1px solid #8EA0CF;
}

.shortcuts-table tr.header{
  background: #8EA0CF;
  font-family: Poppins;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
}

.shortcuts-table th {
  text-align: left;
  color: white;

}
.shortcuts-table th, td {
  padding: 7px;
  padding-top: 5px;
  padding-bottom: 5px;
} 




/** random **/
.monospaced {
  font-family: Courier, serif;
}