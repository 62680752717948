/* adapted from https://www.w3schools.com/howto/tryit.asp?filename=tryhow_css_js_lightbox*/

/** for the lightbox **/

img.hover-shadow {
  transition: 0.3s;
  max-width: 100%;
  max-height: 100%;
}

.hover-shadow:hover {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}
.cursor {
  cursor: pointer;
}

/** modal **/
.modal {
  position: fixed;
  width: 100%;
  height: 100%;
  background: rgba(0,0,0,0.85);
  top: 0px;
  left: 0px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.modal-content {
  position: relative;
  /*width: 60%;
  margin-left: 20%;
  margin-right: 20%;*/
  max-width: 100%;
  max-height: auto;
  height: 75%; /* Generally, the width of screen is bigger than height*/
  width: auto;
  margin-top: auto;
  margin-bottom: auto;
  overflow: hidden;
  object-fit: contain;
}

.close {
  position: absolute;
  top: 3vh;
  right: 3vw;
  cursor: pointer;
  color: white;  
  font-size: 35px;
  font-weight: bold;
}